import * as Sentry from '@sentry/nextjs';
import { isSupplierApp } from 'lib/helpers/routes';
import useToast from 'lib/hooks/interactions/useToast';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { fetchSessionFromAPI } from './fetchSessionFromAPI';
import { Session } from './types';

export type PartialSession = Partial<{
  user: Partial<Session['user']>;
  team: Partial<Session['team']>;
  shop: Partial<Session['shop']>;
  membership: Partial<Session['membership']>;
}>;

export type SessionContextValue = {
  /**
   * Not to be confused with the typical isLoading boolean, this is true if the
   * loading has finished and we have a session object.
   */
  hasFinishedLoading: boolean;

  /**
   * The current session. If the session is not loaded yet, this will be null.
   * Contains the user, team, shop and membership information.
   */
  session: Session;

  /**
   * Sets the session object. This is used internally by the provider to load the
   * session from the API.
   */
  setSession: React.Dispatch<React.SetStateAction<Session | null>>;

  /**
   * Sets the session's user, team, shop and membership information to null.
   */
  clearSession: () => void;

  /**
   * Updates the session's user, team, shop and membership information with the
   * provided partial values. Any fields not provided will be left unchanged.
   *
   * If the pre-existing user, team, shop, membership or the session itself is null, no changes will be made.
   */
  updateSession: (session: PartialSession) => void;

  /**
   * Loads the session from the API. If the session is already loaded, this
   * function does nothing unless the `force` parameter is set to true.
   */
  loadSession: (force?: boolean) => Promise<Session>;

  /**
   * Reloads the session from the API, even if it's already loaded. We can optionally
   * force the Segment identify call to happen again, which is necessary when the user
   * is switching between teams.
   */
  reloadSession: (forceIdentifyCall?: boolean) => Promise<Session>;
};

export const SessionContext = React.createContext<SessionContextValue | undefined>(undefined);

interface Props {
  children: React.ReactNode;
  initialRawSession?: Session | null;
}

const EMPTY_SESSION = {
  user: null,
  team: null,
  shop: null,
  membership: null,
  isBlockedFromNewPartnerships: false,
};

/**
 * Wrapper that enables children to access session information using the
 * `useSession` hook. Defines the `loadSession` logic that actually deals with
 * parsing API data into a `Session` object`. Without it, `useSession` will not
 * work.
 */
const SessionProvider = ({ children, initialRawSession = null }: Props) => {
  const [rawSession, setSession] = useState<Session | null>(initialRawSession);
  const [isLoading, setIsLoading] = useState(false);
  const showToast = useToast();
  const hasIdentifiedUser = useRef(false);

  const session = rawSession ?? EMPTY_SESSION;
  const clearSession = useCallback(() => setSession(EMPTY_SESSION), []);
  const updateSession = useCallback((partial: PartialSession) => {
    const { user = {}, team = {}, shop = {}, membership = {} } = partial;
    setSession((prev) => {
      if (prev === null) return null;
      const updatedUser = prev.user === null ? null : { ...prev.user, ...user };
      const updatedTeam = prev.team === null ? null : { ...prev.team, ...team };
      const updatedShop = prev.shop === null ? null : { ...prev.shop, ...shop };
      const updatedMembership =
        prev.membership === null ? null : { ...prev.membership, ...membership };

      return {
        user: updatedUser,
        team: updatedTeam,
        shop: updatedShop,
        membership: updatedMembership,
        isBlockedFromNewPartnerships: prev.isBlockedFromNewPartnerships,
      };
    });
  }, []);
  const loadSession = useCallback(
    async (force = false, forceIdentifyCall = false) => {
      if (isLoading) {
        // Already loading.
        return rawSession ?? EMPTY_SESSION;
      }

      if (rawSession && !force) {
        // Already loaded.
        return rawSession ?? EMPTY_SESSION;
      }

      setIsLoading(true);
      let newSession = null;
      try {
        const data = await fetchSessionFromAPI(force ? { fetchPolicy: 'network-only' } : undefined);
        const user = data.me;
        const shop = data.loggedInShopifyShop;
        newSession = {
          user,
          shop,
          team: data.loggedInTeam,
          membership: data.loggedInMembership,
          isBlockedFromNewPartnerships:
            !data.loggedInShopifyShop?.canCreateNewRelationship && !isSupplierApp(),
        };
        setSession(newSession);

        // Call Segment `identify` and `group` methods to associate future `track` events
        // with correct user and shop info
        if ((!hasIdentifiedUser.current || forceIdentifyCall) && user && shop && window.analytics) {
          const segmentUserId = `${user.id}${shop.id ? `-${shop.id}` : ''}`;
          window.analytics.identify(segmentUserId, {
            canal_user_id: user.id,
            shop_id: shop.id,
            email: user.email,
            integration_type: shop.remotePlatform,
          });
          const isStorefront = shop?.appsInstalled?.some((v) => v?.toUpperCase().includes('SK'));
          const isSupplier = shop?.appsInstalled?.some((v) => v?.toUpperCase().includes('SUP'));
          window.analytics.group(shop.id, {
            name: shop.name,
            store_url: shop.domain,
            shopify_domain: shop.myshopifyDomain,
            shopify_plan: shop.planName,
            shop_platform: shop.remotePlatform,
            created_at: Math.floor(new Date(shop.createdAt).getTime() / 1000),
            currated_commerce: shop.isCuratedCommerce,
            multi_location_shipping_enabled: !!shop.multiLocationEnabled,
            email_passthrough_setting: shop.emailPassthrough,
            shop_status: shop.shopStatus,
            onboarding_complete: shop.onboardingComplete,
            is_storefront: isStorefront,
            is_supplier: isSupplier,
            is_gondolier: isSupplier || isStorefront,
            has_shipping_policy_summary: !!shop.supplierShippingPolicySummary,
          });
          hasIdentifiedUser.current = true;
        }
      } catch (e) {
        setSession(EMPTY_SESSION);
        setIsLoading(false);
        if (!window.location.href.includes('/login') && !window.location.href.includes('/signup')) {
          Sentry.captureException(e);
          showToast('Failed to load session');
        }
        return EMPTY_SESSION;
      }
      setIsLoading(false);
      return newSession;
    },
    [isLoading, rawSession, showToast],
  );

  const reloadSession = useCallback(
    (forceIdentifyCall = false) => loadSession(true, forceIdentifyCall),
    [loadSession],
  );

  const value: SessionContextValue = useMemo(
    () => ({
      session,
      setSession,
      clearSession,
      updateSession,
      loadSession,
      reloadSession,
      // TODO(felipe): calculate this based on a variable like `setLastLoadedAt`
      // so we allow session to have a null value after loading (eg. if an error
      // occured).
      hasFinishedLoading: !isLoading && !!rawSession,
    }),
    [clearSession, isLoading, loadSession, rawSession, reloadSession, session, updateSession],
  );

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
