import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { ApolloProvider } from '@apollo/client';
import '@shopify/polaris/build/esm/styles.css';
import AppStyleProvider from 'components/shared/AppStyleProvider';
import BigCommerceAppBridgeProvider from 'components/shared/BigCommerceAppBridgeProvider';
import ShopifyAppBridgeProvider from 'components/shared/ShopifyAppBridgeProvider';
import GlobalModalProvider from 'components/shared/modals/GlobalModalProvider';
import SessionProvider from 'components/shared/session/SessionProvider';
import ToastProvider from 'components/shared/toast/ToastProvider';
import SharedCartProvider from 'components/shopkeepapp/cart/SharedCartProvider';
import ExportProductsProvider from 'components/supplierapp/listings/ExportProducts/ExportProductsProvider';
import ImportProductsProvider from 'components/supplierapp/listings/ImportProducts/ImportProductsProvider';
import ExportLineItemsProvider from 'components/supplierapp/orders/components/ExportLineItems/ExportLineItemsProvider';
import ImportTrackingProvider from 'components/supplierapp/orders/components/ImportTracking/ImportTrackingProvider';
import ApolloClient from 'lib/api/ApolloClient';
import { SHOPIFY_ENTRYPOINT, ZENDESK_KEY } from 'lib/constants/routes';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import ScheduledMaintenance from 'panama/components/ScheduledMaintenance';
import 'panama/fonts.css';
import { type ReactElement, type ReactNode } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

/**
 * Prefix of the app pages, which require the more complicated providers that
 * public-facing landing pages don't.
 */
const APP_PREFIXES = [
  '/shopkeep',
  '/supplier',
  '/affiliate',
  '/accounts',
  '/new-user',
  '/login',
  '/logout',
  '/coding_tests',
  '/products',
  SHOPIFY_ENTRYPOINT,
  '/refer_supplier',
  '/spyglass',
  '/upsell',
  '/request-invitation',
  '/waitlist',
  '/refer_storefront',
  '/refer_general',
  '/proposal',
  '/sample-request',
  '/onboarding',
  '/wishlists',
  '/signup',
  '/bigcommerce-entrypoint',
];

/**
 * Prefix of the app pages that require the same providers as regular app pages
 * EXCEPT for shopify-related functionality.
 */
const CANAL_APP_PREFIXES = ['/order'];

/**
 * Next.js page type with an optional getLayout function, which is used to
 * wrap the page in a layout component.
 *
 * @see https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
 */
export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

/**
 * Global app component, used across the entire site in all contexts, provided
 * as a wrapper of all pages via Next.js. Initializes/uses the following via
 * React's Provider paradigm/hooks.
 *
 * - Zendesk Chat
 * - Flags: either feature flags or config flags that we use everywhere
 * - ApolloProvider
 * - Style provider (and css for fonts)
 * - ShopifyAppBridgeProvider for synchronizing the embedded app's URL with the
 *   parent Shopify frame.
 * - ToastProvider needed for useToast
 * - SessionProvider needed for useSession hook
 * - ExportProductsProvider for exporting products
 * - ImportProductsProvider for importing products
 * - ExportLineItemsProvider for exporting orders
 *
 */
const App = ({ Component, pageProps, router }: AppPropsWithLayout): React.ReactElement => {
  const { asPath } = router;
  const getLayout = Component.getLayout ?? ((page) => page);

  // Constant to toggle the scheduled maintenance page. Turn this on whenever we have scheduled
  // downtime on the backend and the app is unusable.
  // Note: we can't use a feature flag here because they are controlled by the backend
  const showScheduledMaintenancePage = false;

  const isAppPage = APP_PREFIXES.some((prefix) => asPath.startsWith(prefix));
  if (isAppPage) {
    return (
      <ApolloProvider client={ApolloClient}>
        <AppStyleProvider>
          {!showScheduledMaintenancePage && (
            <BigCommerceAppBridgeProvider>
              <ShopifyAppBridgeProvider>
                <HubspotProvider>
                  <GlobalModalProvider>
                    <ToastProvider>
                      <SessionProvider>
                        <ExportProductsProvider>
                          <ImportProductsProvider>
                            <ExportLineItemsProvider>
                              <ImportTrackingProvider>
                                <SharedCartProvider>
                                  {getLayout(<Component {...pageProps} />)}
                                </SharedCartProvider>
                              </ImportTrackingProvider>
                            </ExportLineItemsProvider>
                          </ImportProductsProvider>
                        </ExportProductsProvider>
                      </SessionProvider>
                    </ToastProvider>
                  </GlobalModalProvider>
                </HubspotProvider>
              </ShopifyAppBridgeProvider>
            </BigCommerceAppBridgeProvider>
          )}
          {showScheduledMaintenancePage && <ScheduledMaintenance />}
        </AppStyleProvider>
        <Zendesk
          defer
          zendeskKey={ZENDESK_KEY}
          onLoaded={() => {
            ZendeskAPI('messenger', 'close');
          }}
        />
      </ApolloProvider>
    );
  }

  const isCanalAppPage = CANAL_APP_PREFIXES.some((prefix) => asPath.startsWith(prefix));
  if (isCanalAppPage) {
    return (
      <ApolloProvider client={ApolloClient}>
        <ToastProvider>
          {getLayout(<Component {...pageProps} />)}
          <Zendesk
            defer
            zendeskKey={ZENDESK_KEY}
            onLoaded={() => {
              ZendeskAPI('messenger', 'close');
            }}
          />
        </ToastProvider>
      </ApolloProvider>
    );
  }

  return (
    <AppStyleProvider>
      <ShopifyAppBridgeProvider>{getLayout(<Component {...pageProps} />)}</ShopifyAppBridgeProvider>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => {
          ZendeskAPI('messenger', 'close');
        }}
      />
    </AppStyleProvider>
  );
};

export default App;
